


































































































































































































































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import { mapActions, mapGetters } from "vuex";
import i18n from "@/i18n/i18n";
export default Vue.extend({
  components: {
    Layout,
  },

  data() {
    return {
      circleCheck: require("@/assets/images/circle-check-subscribtion-plan.svg"),
      circleCross: require("@/assets/images/circle-cross-subscribtion-plan.svg"),
      options: [{ title: "Month" }, { title: "Year" }],
      selected: "Month",
    };
  },

  async mounted() {
    await this.setProducts();
    await this.setTrialPeriod();
    await this.setTaxPricePeriodTranslation();
  },

  computed: {
    ...mapGetters("azTrialPeriodModule", {
      trialPeriod: "GET_TRIAL_PERIOD",
    }),
    ...mapGetters("azProductsModule", {
      productBasicMonth: "GET_PRODUCT_BASIC_MONTH",
      productBasicYear: "GET_PRODUCT_BASIC_YEAR",
      productPremiumMonth: "GET_PRODUCT_PREMIUM_MONTH",
      productPremiumYear: "GET_PRODUCT_PREMIUM_YEAR",
      additionalServicesMonth: "GET_ACTIVE_ADDITIONAL_SERVICES_MONTH",
      additionalServicesYear: "GET_ACTIVE_ADDITIONAL_SERVICES_YEAR",
    }),
    ...mapGetters("azTaxPricePeriodTranslationModule", {
      taxPricePeriodTranslation: "GET_TAX_PRICE_PERIOD_TRANSLATION",
    }),
    additionalProducts() {
      return this.selected == "Month"
        ? this.additionalServicesMonth
        : this.additionalServicesYear;
    },
    activeBasicSubscriptionDescriptions() {
      if (!this.productBasicMonth[0].subscriptionDescriptions) return "";
      let filteredDescriptions =
        this.productBasicMonth[0].subscriptionDescriptions.filter(
          subscriptionDescription => subscriptionDescription.deletedAt == null
        );
      let filteredAndSortedDescriptions = filteredDescriptions.sort((a, b) => {
        return b.id - a.id;
      });
      return filteredAndSortedDescriptions;
    },
    activePremiumSubscriptionDescriptions() {
      if (!this.productPremiumMonth[0].subscriptionDescriptions) return "";
      let filteredDescriptions =
        this.productPremiumMonth[0].subscriptionDescriptions.filter(
          subscriptionDescription => subscriptionDescription.deletedAt == null
        );
      let filteredAndSortedDescriptions = filteredDescriptions.sort((a, b) => {
        return b.id - a.id;
      });
      return filteredAndSortedDescriptions;
    },
    unAssignedBasicSubscriptionDescriptions() {
      if (!this.activePremiumSubscriptionDescriptions) return "";
      return this.activePremiumSubscriptionDescriptions.filter(
        ({ id: id1 }) =>
          !this.activeBasicSubscriptionDescriptions.some(
            ({ id: id2 }) => id2 == id1
          )
      );
    },
    locale(): string {
      return i18n.locale;
    },
    getAdditionalServiceTranslatedPrice(): string {
      if (this.selected == "month") {
        return this.locale == "en"
          ? this.taxPricePeriodTranslation[0].priceAndPeriodMonthEn
          : this.taxPricePeriodTranslation[0]
              .isPriceAndPeriodMonthTranslationCompleted
          ? this.taxPricePeriodTranslation[0].priceAndPeriodMonthSk
          : this.taxPricePeriodTranslation[0].priceAndPeriodMonthEn;
      } else {
        return this.locale == "en"
          ? this.taxPricePeriodTranslation[0].priceAndPeriodYearEn
          : this.taxPricePeriodTranslation[0]
              .isPriceAndPeriodYearTranslationCompleted
          ? this.taxPricePeriodTranslation[0].priceAndPeriodYearSk
          : this.taxPricePeriodTranslation[0].priceAndPeriodYearEn;
      }
    },
  },

  methods: {
    ...mapActions("azTrialPeriodModule", {
      setTrialPeriod: "FETCH_TRIAL_PERIOD",
    }),
    ...mapActions("azProductsModule", {
      setProducts: "FETCH_PRODUCTS",
    }),
    ...mapActions("azTaxPricePeriodTranslationModule", {
      setTaxPricePeriodTranslation: "FETCH_TAX_PRICE_PERIOD_TRANSLATION",
    }),
  },
});
